
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ArticleQuote from '@/components/ArticleQuote.vue';

    import contentTools from '@/mixins/contentTools';

    @Component({
        components: {
            ArticleQuote,
        },
        mixins: [contentTools],
    })
    export default class ArticleParagraph extends Vue {
        @Prop({ type: Object }) private paragraph!: any;
        @Prop({ type: Number }) private gridSectionIndex!: number;
        @Prop({ type: Number }) private articleElementIndex!: number;

        get currentHost() {
            return document.location.host;
        }

        private mounted() {
            this.addBlankToAnchors();
        }

        private addBlankToAnchors() {
            const anchors = [...document.getElementsByTagName('a')];

            anchors.map((anchor) => {
                if (!anchor.href.includes(this.currentHost)) {
                    anchor.setAttribute('target', '_blank');
                }

                return anchor;
            });
        }
    }
