
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Highlight extends Vue {
        @Prop({ type: String, default: '' }) private readonly image!: string | null;
        @Prop({ type: String, default: '' }) private readonly alt!: string | null;
        @Prop({ type: String, default: '' }) private readonly title!: string;
        @Prop({ type: String, default: '' }) private readonly annotation!: string;
    }
