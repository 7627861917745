
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import cleanNbsp from '@/helpers/cleanNbsp';

    const typeValues = ['pull', 'inline', 'block'];
    const floatValues = ['left', 'right', null];

    @Component
    export default class ArticleHero extends Vue {
        @Prop({
            type: String,
            default: 'pull',
            required: true,
            validator: (value: string) => typeValues.includes(value),
        })
        private readonly quoteType!: string;
        @Prop({
            type: String,
            default: null,
            required: false,
            validator: (value: string) => floatValues.includes(value),
        })
        private readonly quoteFloat?: string;
        @Prop({ type: String, default: '' }) private readonly quoteTitle!: string;
        @Prop({ type: String, required: true }) private readonly quoteText!: string;
        @Prop({ type: String, default: '' }) private quoteSource!: string;

        private cleanNbsp = cleanNbsp;
    }
