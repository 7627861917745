import ArticleData from '@/types/ArticleData.ts';
import getArticleImageUrl from '@/helpers/getArticleImageUrl';

const imageHelper = {
    methods: {
        getImageUrl(articleData: ArticleData): string {
            return getArticleImageUrl(articleData);
        },
        getImageAlt(asset: Array<{ fields: { description: string } }>): string {
            if (!asset || asset.length === 0 || !asset[0].fields || !asset[0].fields.description) {
                return '';
            }

            return asset[0].fields.description;
        },
    },
};

export default imageHelper;
