
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import InfoGraphicDescription from '@/types/InfoGraphicDescription';

    const numberFormatDe = new Intl.NumberFormat('de-DE');

    @Component
    export default class InfoGraphicTriangle extends Vue {
        @Prop({ type: Array, required: true }) private descriptions!: InfoGraphicDescription[];
        @Prop({ type: String, required: true }) private asideText!: string;
        @Prop({ type: Number, required: true }) private asideRowSpanFrom!: number;
        @Prop({ type: Number, required: true }) private asideRowSpanTo!: number;

        get rowspan(): number {
            return this.asideRowSpanTo - this.asideRowSpanFrom + 1;
        }
    }
