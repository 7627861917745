
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import getSrcSet from '@/helpers/getSrcSet';

    import SrcSetElement from '@/types/SrcSetElement';

    const imgPositions = ['left', 'right'];

    @Component
    export default class ImageAnnotation extends Vue {
        @Prop({ type: String, default: 'left', validator: (value: string) => imgPositions.includes(value) })
        private readonly imgPosition!: string;
        @Prop({ type: String, default: null }) private readonly imgSrc!: string;
        @Prop({
            type: Array,
            default() {
                return [];
            },
        })
        private imgSrcSet!: SrcSetElement[];
        @Prop({ type: String, default: null }) private readonly imgAlt!: string;
        @Prop({ type: String, default: null }) private readonly imgCaption!: string;
        protected getSrcSet = () => getSrcSet(this.imgSrcSet);
    }
