
    import { Component, Vue } from 'vue-property-decorator';

    import getClient from '@/helpers/contentfulClient';

    import ArticleData from '@/types/ArticleData';

    import ArticleFooterLinks from '@/components/ArticleFooterLinks.vue';

    @Component({
        components: {
            ArticleFooterLinks,
        },
        metaInfo: {
            title: 'Finanzwissen - 404 / Seite nicht gefunden',
            meta: [
                {
                    name: 'description',
                    content: '404 - Die gesuchte Seite konnte nicht gefunden werden',
                },
            ],
        },
    })
    export default class NotFound extends Vue {
        private recommendedArticles: ArticleData[] = [];

        private async mounted() {
            // Fetch 2 last updated articles
            getClient().then((contentfulClient) => {
                const data = contentfulClient.getEntries({
                    content_type: 'article',
                    order: '-sys.updatedAt',
                    limit: 2,
                });

                this.recommendedArticles = data.items.map((item: any) => {
                    return item.fields;
                });
            });
        }
    }
