
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import Category from '@/types/Category.ts';
    import getCategories from '@/helpers/getCategories.ts';
    import { trackClick } from '@/helpers/webTrekk';

    @Component
    export default class ArticleFilters extends Vue {
        private activeCategory: Category = {
            slug: null,
            label: 'Alle Themen',
        };
        private categories: Category[] = [];
        private loading: boolean = true;

        public setActiveCategory(category: Category) {
            this.activeCategory = category;
            this.$store.dispatch('setCurrentFilter', this.activeCategory.label);
            this.$emit('filterSelect', this.activeCategory.slug);
            window.requestAnimationFrame(this.moveLine);
        }

        public isActive(category: Category) {
            return category.slug === this.activeCategory.slug;
        }

        public async mounted() {
            this.loading = true;

            try {
                const data: { items: Array<{ fields: Category }> } = await getCategories;
                const categoriesData: Category[] = data.items.map((item: any) => item.fields);

                this.categories = [
                    {
                        slug: null,
                        label: 'Alle Themen',
                    },
                    ...categoriesData,
                ];

                if (this.$route.params.categorySlug) {
                    this.findActiveCategoryFromUrl();
                }

                window.requestAnimationFrame(this.moveLine);
                window.addEventListener('resize', this.onResize);
            } catch (error) {
                // TODO: handle errors
            }

            this.loading = false;
        }

        public updated() {
            if (this.$store.getters.getIsNavigationByKeyboard) {
                (this.$refs.focusTarget as HTMLElement[])[0].focus();
            }
        }

        private onResize() {
            if (window.innerWidth > 740) {
                // from medium breakpoint and up
                window.requestAnimationFrame(this.moveLine);
            }
        }

        private findActiveCategoryFromUrl() {
            const activeCategory = this.categories.find((category) => {
                return category.slug === this.$route.params.categorySlug;
            });

            if (activeCategory) {
                this.activeCategory = activeCategory;
            }

            // User tried to access category that does not exist
            if (!activeCategory) {
                this.$emit('notFound');
            }
        }

        private moveLine() {
            const activeCategory = document.querySelector('.filters__link--active') as HTMLElement;
            const dimensions = activeCategory.getBoundingClientRect();

            (this.$refs.line as HTMLElement).style.width = dimensions.width + 'px';
            (this.$refs.line as HTMLElement).style.left = activeCategory.offsetLeft + 'px';
        }

        private destroyed() {
            window.removeEventListener('resize', this.onResize);
        }

        private unfocus({ target }: { target: HTMLButtonElement }) {
            target.blur();
        }

        private trackFilterClick(category: string) {
            trackClick(`Filter click: ${category}`);
        }

        @Watch('activeCategory')
        private onSelectModelChange() {
            this.$emit('filterSelect', this.activeCategory.slug);
        }
    }
