import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        articles: null,
        currentFilter: '',
        disclaimerMarketingmitteilung: null,
        disclaimerRisikohinweis: null,
        navigationByKeyboard: false,
        packageVersion: process.env.PACKAGE_VERSION || '0',
        externalConfigData: {},
    },
    mutations: {
        UPDATE_ARTICLES(state, data) {
            state.articles = data;
        },
        SET_CURRENT_FILTER(state, data) {
            state.currentFilter = data;
        },
        SET_DISCLAIMER_MARKETINGMITTEILUNG(state, data) {
            state.disclaimerMarketingmitteilung = data;
        },
        SET_DISCLAIMER_RISIKOHINWEIS(state, data) {
            state.disclaimerRisikohinweis = data;
        },
        SET_NAVIGATION_BY_KEYBOARD(state, data) {
            state.navigationByKeyboard = data;
        },
        SET_EXTERNAL_CONFIG_DATA(state, data) {
            state.externalConfigData = data;
        },
    },
    actions: {
        updateArticles(context, data) {
            context.commit('UPDATE_ARTICLES', data);
        },
        setCurrentFilter(context, data) {
            context.commit('SET_CURRENT_FILTER', data);
        },
        setDisclaimerMarketingmitteilung(context, data) {
            context.commit('SET_DISCLAIMER_MARKETINGMITTEILUNG', data);
        },
        setDisclaimerRisikohinweis(context, data) {
            context.commit('SET_DISCLAIMER_RISIKOHINWEIS', data);
        },
        setNavigationHardware(context, data) {
            context.commit('SET_NAVIGATION_BY_KEYBOARD', data);
        },
        setExternalConfigData(context, data) {
            context.commit('SET_EXTERNAL_CONFIG_DATA', data);
        },
    },
    getters: {
        getAppVersion: (state) => state.packageVersion,
        getCurrentFilter: (state) => state.currentFilter,
        getArticles: (state) => state.articles,
        getDisclaimerMarketingmitteilung: (state) => state.disclaimerMarketingmitteilung,
        getDisclaimerRisikohinweis: (state) => state.disclaimerRisikohinweis,
        getIsNavigationByKeyboard: (state) => state.navigationByKeyboard,
        getExternalConfigData: (state) => state.externalConfigData,
    },
});
