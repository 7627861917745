
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import imageHelper from '@/mixins/imageHelper';
    import { trackClick } from '@/helpers/webTrekk';

    const cardSizes = ['big', 'medium', 'small'];

    @Component({
        mixins: [imageHelper],
    })
    export default class Card extends Vue {
        @Prop({
            type: String,
            default: 'small',
            required: true,
            validator: (value: string) => cardSizes.includes(value),
        })
        private readonly cardSize!: string;
        @Prop({ type: Array }) private aticleIllustration!: object[];
        @Prop({ type: String, required: true }) private category!: string;
        @Prop({ type: String, default: null, required: true }) private slug!: string;
        @Prop({ type: String, required: true }) private title!: string;
        @Prop({ type: String, required: true }) private teaser!: string;
        @Prop({ type: Number, default: null, required: false }) private cardIndex?: number;

        get sizes(): string {
            switch (this.cardSize) {
                case 'big':
                    return '(min-width: 1051px) 650px, (min-width: 741px) 335px, calc(100vw - 40px)';
                case 'medium':
                    return '(min-width: 1051px) 480px, (min-width: 741px) 335px, calc(100vw - 40px)';
                default:
                    return '(min-width: 1051px) 310px, (min-width: 741px) 335px, calc(100vw - 40px)';
            }
        }

        get href() {
            return `/${this.slug}`;
        }

        get imgUrl() {
            return this.aticleIllustration && this.aticleIllustration[0]
                ? (this.aticleIllustration[0] as { fields: { file: { url: string } } }).fields.file.url
                : 'https://www.fillmurray.com/320/180';
        }

        private getImgSrcSet() {
            const progressiveImgeUrl = `${this.imgUrl}?fm=jpg&fl=progressive`;
            const sizes = ['2800 2800w', '1400 1400w', '1000 1000w', '800 800w', '600 600w', '400 400w'];
            let srcSetUrl = '';

            sizes.forEach((size: string) => {
                srcSetUrl = srcSetUrl + `${progressiveImgeUrl}&w=${size},`;
            });

            return this.imgUrl.endsWith('.jpg') ? srcSetUrl.slice(0, -1) : false;
        }

        private trackCardClick() {
            if (this.cardIndex != null) {
                trackClick(
                    `Article card click: ${
                        this.$store.getters.getCurrentFilter !== '' ? `${this.$store.getters.getCurrentFilter}_` : ''
                    }${this.cardIndex}_${this.slug}`,
                );
            }

            return true;
        }
    }
