var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"grid__line"},[_vm._l((_vm.paragraph.content),function(paragraphElement,paragraphElementIndex){return [(paragraphElement.nodeType === 'text' && paragraphElement.value !== '')?_c('span',{key:`paragraphElement-${paragraphElementIndex}-${_vm.gridSectionIndex}-${_vm.articleElementIndex}`,class:paragraphElement.marks.length === 0 ? false : `is-${paragraphElement.marks[0].type}`,domProps:{"innerHTML":_vm._s(paragraphElement.value)}}):(
                paragraphElement.nodeType === 'embedded-entry-inline' &&
                _vm.getElementId(paragraphElement) === 'articleQuote'
            )?_c('article-quote',{key:`paragraphElement-${paragraphElementIndex}-${_vm.gridSectionIndex}-${_vm.articleElementIndex}`,staticClass:"grid__line",attrs:{"quoteTitle":paragraphElement.data.target.fields.title,"quoteText":paragraphElement.data.target.fields.text.content[0].content === 1
                    ? paragraphElement.data.target.fields.text.content[0].content[0].value
                    : _vm.getElementTextWithLinks(paragraphElement),"quoteSource":paragraphElement.data.target.fields.source,"quoteType":"pull","quoteFloat":_vm.getQuoteFloatClass(paragraphElement.data.target.fields.float)}}):(paragraphElement.nodeType === 'hyperlink')?_c('a',{key:`hyperlinkElement-${paragraphElementIndex}-${_vm.gridSectionIndex}-${_vm.articleElementIndex}`,attrs:{"href":paragraphElement.data.uri,"target":paragraphElement.data.uri.includes(_vm.currentHost) ? false : '_BLANK',"tabindex":"0"}},[_vm._v(_vm._s(paragraphElement.content[0].value))]):(
                paragraphElement.nodeType === 'entry-hyperlink' &&
                paragraphElement.data.target &&
                paragraphElement.data.target.fields &&
                paragraphElement.data.target.fields.slug &&
                paragraphElement.data.target.fields.slug != null
            )?_c('a',{attrs:{"href":`/${paragraphElement.data.target.fields.slug}`,"tabindex":"0"}},[_vm._v(" "+_vm._s(paragraphElement.content[0].value)+" ")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }