import isHTMLElement from './isHTMLElement';

const getParentByClassname = (node: Node, className: string) => {
    let currentNode = node.parentNode;

    while (currentNode) {
        if (isHTMLElement(currentNode) && (currentNode as HTMLElement).classList.contains(className)) {
            return currentNode;
        }

        currentNode = currentNode.parentNode;
    }

    return currentNode;
};

export default getParentByClassname;
