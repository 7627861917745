
    import { Component, Prop, Vue } from 'vue-property-decorator';

    const layoutTypes = ['horizontal', 'vertical'];

    @Component({
        props: {
            layout: String,
            title: String,
            headers: Array,
            cells: Array,
            emphasizes: Array,
        },
    })
    export default class TableComponent extends Vue {
        @Prop({ type: String, default: 'horizontal', validator: (value: string) => layoutTypes.includes(value) })
        private readonly layout!: string;
        @Prop({ type: String, default: '' }) private readonly title!: string;
        @Prop({ type: Array, default: () => [] }) private readonly headers!: string;
        @Prop({ type: Array, default: () => [] }) private readonly cells!: string;
        @Prop({ type: Array, default: () => [] }) private readonly emphasizes!: string;
    }
