
    import { Component, Prop, Vue } from 'vue-property-decorator';
    // import { trackClick } from '@/helpers/webTrekk';

    @Component({
        props: {
            href: String,
            label: String,
            title: String,
            target: { type: String, default: '_blank' },
        },
    })
    export default class FooterLink extends Vue {
        @Prop({ type: String, default: '/' }) private readonly href!: string;
        @Prop({ type: String, default: '' }) private readonly label!: string;
        @Prop({ type: String, default: '' }) private readonly title!: string;

        // private trackClick() {
        // trackClick(this.label.trim().toLowerCase() === 'dkb.de' ? 'Footerdkb.de.DE' : `RF ${this.label}`);
        // }
    }
