
    import { Component, Prop, Vue } from 'vue-property-decorator';

    const positions = ['top', 'top-left', 'top-right', 'bottom-lefT', 'bottom-right'];

    @Component({
        props: {
            title: String,
            percentages: Array,
            source: String,
        },
    })
    export default class InfoGraphicLegend extends Vue {
        @Prop({ type: String, default: 'top-left', validator: (value: string) => positions.includes(value) })
        private readonly position!: string;
        @Prop({ type: String, required: true }) private title!: string;
        @Prop({ type: [String, Array], default: () => '', required: false }) private items: any;
        @Prop({ type: String, default: '', required: false }) private source!: string;
    }
