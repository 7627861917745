import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
    extractFields,
    getColorBlockListItems,
    getColorBlockParagraphs,
    getColorBlockThemeFor,
    getElementId,
    getNestedFieldValue,
    getQuoteFloatClass,
    getTableLayoutFor,
    getWidthClass,
} from '@/helpers/contentHelper';

const contentTools = {
    methods: {
        // imported, relayed functions:
        getElementId,
        fieldValue(element: any, fieldName: string): string {
            return getNestedFieldValue(element, fieldName);
        },
        getWidthClass,
        getColorBlockThemeFor,
        getTableLayoutFor,
        getColorBlockParagraphs,
        getColorBlockListItems,
        getQuoteFloatClass,
        // Fuctions native to this mixin:
        // Use to extract only the fields from Contentful response with an array of data:
        extractFields,
        unorderedListItems(htmlTree: any) {
            const options = {
                renderNode: {
                    [BLOCKS.PARAGRAPH]: (node: any, next: any) => `${next(node.content)}`,
                },
            };

            let html = documentToHtmlString(htmlTree, options);
            // We must unescape the escaped characters inserted inside the paragraphs
            // Editors should not add anchor tags in the rich text editor by adding html
            // instead they should use the rich text editor UI to insert a link
            html = html.split('&shy;').join('-');
            html = html.split('&lt;').join('<');
            html = html.split('&gt;').join('>');
            html = html.split('&quot;').join('"');

            return html;
        },
        getElementTextWithLinks(element: any) {
            let result = '';
            for (const el of element.data.target.fields.text.content[0].content) {
                if (el.nodeType === 'text') {
                    if (el.hasOwnProperty('value')) {
                        result = result.concat(el.value);
                    } else {
                        result = result.concat(el.content[0].value);
                    }
                } else if (el.nodeType === 'hyperlink' && el.data.uri.includes('dkb.de')) {
                    result = result.concat(`<a href="${el.data.uri}" tabindex="0">${el.content[0].value}</a>`);
                } else if (el.nodeType === 'hyperlink' && !el.data.uri.includes('dkb.de')) {
                    result = result.concat(
                        `<a href="${el.data.uri}" target="_blank" tabindex="0">${el.content[0].value}</a>`,
                    );
                } else if (el.nodeType === 'entry-hyperlink') {
                    result = result.concat(
                        el.data.target
                            ? `<a href="/${el.data.target.fields.slug}" tabindex="0">${el.content[0].value}</a>`
                            : el.content[0].value,
                    );
                }
            }
            return result as string;
        },
    },
};

export default contentTools;
