import ExternalConfigData from '@/types/ExternalConfigData';

let configData: ExternalConfigData;

const getConfigData = async () => {
    if (!configData) {
        if (process.env.NODE_ENV === 'development') {
            configData = require('../../config/config-dev.json');
        } else {
            const response = await fetch('/js/config.json');            
            configData = await response.json();
        }
    }

    return configData;
};

export default getConfigData;
