
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import SrcSetElement from '@/types/SrcSetElement';

    import getSrcSet from '@/helpers/getSrcSet';

    import EventBus from '@/EventBus';

    @Component
    export default class ArticleHero extends Vue {
        @Prop({ type: String, default: 'Zur Übersicht' }) private heroBackLabel!: string;
        @Prop({ type: String }) private heroImgSrc!: string;
        @Prop({ type: String }) private heroImgAlt!: string;
        @Prop({ type: String, required: true }) private heroCategory!: string;
        @Prop({ type: String, required: true }) private heroTitle!: string;
        @Prop({ type: String, required: true }) private heroTeaser!: string;
        @Prop({ type: String, required: true }) private heroDateUpdated!: string;
        @Prop({ type: String, required: true }) private heroReadingTime!: string;
        @Prop({ type: String, default: '/' }) private heroBack!: string;
        private heroImgSrcSet!: SrcSetElement[];
        private isMounted: boolean = false;
        private imageLoaded: boolean = false;

        public beforeMount() {
            EventBus.$on('focusBackLink', this.focusBackLink);
        }

        public mounted() {
            this.isMounted = true;

            EventBus.$emit('focusBackLink');
        }

        protected getSrcSet = () => getSrcSet(this.heroImgSrcSet);

        private focusBackLink(e: any) {
            if (this.$refs.focusTarget == null || !this.$store.getters.getIsNavigationByKeyboard) {
                return;
            }

            (this.$refs.focusTarget as HTMLElement).focus();
        }

        private backToOverview() {
            this.$router.push({
                path: this.heroBack,
            });
        }

        private onLoaded() {
            this.imageLoaded = true;
        }
    }
