import { render, staticRenderFns } from "./ArticleParagraph.vue?vue&type=template&id=51685e08&scoped=true&v-if=paragraph.nodeType%20%3D%3D%3D%20'paragraph'"
import script from "./ArticleParagraph.vue?vue&type=script&lang=ts"
export * from "./ArticleParagraph.vue?vue&type=script&lang=ts"
import style0 from "./ArticleParagraph.vue?vue&type=style&index=0&id=51685e08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51685e08",
  null
  
)

export default component.exports