
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import BackToTop from '@/components/BackToTop.vue';
    import FooterLink from '@/components/FooterLink.vue';

    @Component({
        components: {
            BackToTop,
            FooterLink,
        },
    })
    export default class Footer extends Vue {
        public showPrivacyCenter(): void {
            if (window && (window as any).tC) {
                (window as any).tC.privacy.showPrivacyCenter();
            }
        }
        protected data() {
            return {
                footerLinks: [
                    {
                        href: 'https://www.dkb.de/info/impressum/',
                        label: 'Impressum',
                        title: 'Impressum',
                    },
                    {
                        href: 'https://www.dkb.de/kundenservice/datenschutz/',
                        label: 'Datenschutz',
                        title: 'Datenschutz',
                    },
                    {
                        href: 'https://fragen.dkb.de/',
                        label: 'Fragen und Antworten',
                        title: 'Fragen und Antworten',
                    },
                    {
                        href: 'https://www.dkb.de/info/kontakt/',
                        label: 'Kontakt',
                        title: 'Kontakt',
                    },
                    {
                        href: 'https://geldverbesserer.dkb.de/',
                        label: '#geldverbesserer',
                        title: '#geldverbesserer',
                    },
                    {
                        href: 'https://www.dkb.de/',
                        label: 'DKB.de',
                        title: 'Link zur Startseite der Deutsche Kreditbank AG',
                    },
                ],
            };
        }
    }
