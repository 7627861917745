
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import getParentByClassname from '@/helpers/getParentByClassname';
    import { trackClick } from '@/helpers/webTrekk';

    @Component
    export default class CopyToClipboard extends Vue {
        private label = {
            active: 'Kopieren',
            inactive: 'Kopiert',
        };
        private copying = false;
        private url = `${window.location.origin}${window.location.pathname}`;

        @Watch('$route')
        public onRouteChange() {
            this.url = `${window.location.origin}${window.location.pathname}`;
        }

        private copy() {
            this.copying = true;
            const copyInput = this.$refs.copy as HTMLFormElement;
            copyInput.disabled = false;
            copyInput.select();
            copyInput.disabled = true;
            document.execCommand('copy');
            copyInput.blur();

            trackClick(`Clipboard: ${this.url}`);

            setTimeout(() => {
                this.copying = false;

                if (window.getSelection) {
                    (window.getSelection() as Selection).removeAllRanges();
                }
            }, 5000);
        }

        private isSupported() {
            return document.queryCommandSupported('copy');
        }

        private clearCopyState(e: Event) {
            if (
                !getParentByClassname(e.target as Node, 'copy__button') &&
                !(e.target as HTMLElement).classList.contains('copy__button')
            ) {
                this.copying = false;
            }
        }

        private mounted() {
            window.addEventListener('click', this.clearCopyState);
        }

        private destroyed() {
            window.removeEventListener('click', this.clearCopyState);
        }
    }
