
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import InfoGraphicLegend from '@/components/InfoGraphicLegend.vue';
    import PercentageEntry from '@/types/PercentageEntry';
    import PercentageEntryCalculated from '@/types/PercentageEntryCalculated';

    const numberFormatDe = new Intl.NumberFormat('de-DE');

    @Component({
        components: {
            InfoGraphicLegend,
        },
        props: {
            percentages: Array,
            source: String,
            title: String,
        },
    })
    export default class InfoGraphicBars extends Vue {
        @Prop({ type: String, required: true }) private title!: string;
        @Prop({ type: Array, required: true }) private percentages!: PercentageEntry[];
        @Prop({ type: String, default: '', required: false }) private source!: string;

        get entries(): PercentageEntryCalculated[] {
            const maxPercentage = this.percentages.reduce((acc, percentage) => Math.max(acc, percentage.value), 0);

            return this.percentages.map((percentage: PercentageEntry) => ({
                label: percentage.label,
                value: numberFormatDe.format(percentage.value),
                width: (percentage.value / maxPercentage) * 100,
            }));
        }
    }
