
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ArticleParagraph from '@/components/ArticleParagraph.vue';
    import ArticleQuote from '@/components/ArticleQuote.vue';
    import ChecklistComponent from '@/components/ChecklistComponent.vue';
    import InfoGraphicLegend from '@/components/InfoGraphicLegend.vue';
    import Highlight from '@/components/Highlight.vue';
    import ImageAnnotation from '@/components/ImageAnnotation.vue';
    import StickyTableOfContents from '@/components/StickyTableOfContents.vue';
    import ColorBlock from '@/components/ColorBlock.vue';
    import TableComponent from '@/components/TableComponent.vue';
    import TableOfContents from '@/components/TableOfContents.vue';
    import InfoGraphicBars from '@/components/InfoGraphicBars.vue';
    import InfoGraphicCircles from '@/components/InfoGraphicCircles.vue';
    import InfoGraphicTriangle from '@/components/InfoGraphicTriangle.vue';
    import DefinitionList from '@/components/DefinitionList.vue';
    import InfoGraphicHorizontalBar from '@/components/InfographicHorizontalBar.vue';
    import Author from '@/components/Author.vue';
    import ProContra from '@/components/ProContra.vue';
    import Banner from '@/components/Banner.vue';

    import contentTools from '@/mixins/contentTools';
    import { getQuoteFloatClass } from '@/helpers/contentHelper';
    import { elements } from 'vue-meta/types/vue-meta';

    @Component({
        components: {
            ArticleParagraph,
            ArticleQuote,
            ChecklistComponent,
            InfoGraphicLegend,
            Highlight,
            ImageAnnotation,
            StickyTableOfContents,
            ColorBlock,
            TableComponent,
            TableOfContents,
            InfoGraphicBars,
            InfoGraphicCircles,
            InfoGraphicTriangle,
            DefinitionList,
            InfoGraphicHorizontalBar,
            Author,
            ProContra,
            Banner,
        },
        mixins: [contentTools],
    })
    export default class Article extends Vue {
        @Prop({ type: Array }) private gridSections!: any[][];
        @Prop({ type: String, default: 'article' }) private articleType!: string;

        private paragraphIndex: number = 0;

        get isInterview() {
            return this.articleType === 'Interview';
        }

        private paragraphClass(articleElementIndex: number): string {
            if (articleElementIndex === 0) {
                this.paragraphIndex++;
                return this.isInterview ? 'interview__intro' : 'drop-caps';
            }

            return this.paragraphIndex++ % 2 ? '' : 'interview__question';
        }

        private getInfographicImgSrcSet(imgUrl: string) {
            const sizes = [
                '1980 1980w',
                '990 990w',
                '800 800w',
                '600 600w',
                '500 500w',
                '400 400w',
                '350 350w',
                '300 300w',
            ];
            let srcSetUrl = '';

            sizes.forEach((size: string) => {
                srcSetUrl = srcSetUrl + `${imgUrl}?w=${size},`;
            });

            return srcSetUrl.slice(0, -1);
        }
    }
