
    import { Component, Vue } from 'vue-property-decorator';
    import ScrollProgressBar from '@/components/ScrollProgressBar.vue';
    import { trackClick } from '@/helpers/webTrekk';

    @Component({
        components: {
            ScrollProgressBar,
        },
    })
    export default class Header extends Vue {
        public isHomePage(): boolean {
            return ['home'].indexOf(this.$route.name as string) !== -1;
        }

        public isArticlePage(): boolean {
            return ['article'].indexOf(this.$route.name as string) !== -1;
        }

        private trackTitleClick(name: string) {
            trackClick(name);
        }
    }
