
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import TocListItem from '@/types/TocListItem';
    import { trackClick } from '@/helpers/webTrekk';

    @Component
    export default class StickyTableOfContents extends Vue {
        @Prop({ type: Array, default: () => [] }) private readonly list!: TocListItem[];
        @Prop({ type: Boolean, default: false }) private readonly isOrdered!: boolean;

        private trackTocLinkClick(label: string) {
            trackClick(`Sticky ToC click: ${label}`);
        }
    }
