
    import { Component, Prop, Vue } from 'vue-property-decorator';

    const cardSizes = ['big', 'medium', 'small'];

    @Component
    export default class Card extends Vue {
        @Prop({
            type: String,
            default: 'small',
            required: true,
            validator: (value: string) => cardSizes.includes(value),
        })
        private readonly cardSize!: string;
    }
