export const getQueryParams = (queryParamsObj: { [key: string]: string | boolean }) => {
    const params = Object.keys(queryParamsObj).reduce((acc: string[], key) => {
        const value = queryParamsObj[key];

        if (typeof value === 'string' && !(value as string).trim()) {
            return acc;
        }

        acc.push(`${key}=${encodeURIComponent(value)}`);

        return acc;
    }, []);

    return `?${params.join('&')}`;
};

const socialPlatformsBaseUrl = {
    facebook: 'https://www.facebook.com/sharer/sharer.php',
    linkedin: 'https://www.linkedin.com/shareArticle',
    twitter: 'https://twitter.com/intent/tweet',
    xing: 'https://www.xing.com/spi/shares/new',
};

const socialPlatforms: any = {
    facebook: (url: string, title: string, quote: string) => {
        const baseUrl = socialPlatformsBaseUrl.facebook;
        const options = {
            u: url,
            quote: quote ? `"${quote}" –– ${title}` : '',
        };
        const queryParams = getQueryParams(options);

        return `${baseUrl}${queryParams}`;
    },
    linkedin: (url: string) => {
        const baseUrl = socialPlatformsBaseUrl.linkedin;
        const options = {
            mini: true,
            url,
        };
        const queryParams = getQueryParams(options);

        return `${baseUrl}${queryParams}`;
    },
    twitter: (url: string, title: string, quote: string) => {
        const baseUrl = socialPlatformsBaseUrl.twitter;
        const options = {
            text: quote ? `"${quote}" –– ${title}` : title,
            url,
            via: 'dkb_de',
        };
        const queryParams = getQueryParams(options);

        return `${baseUrl}${queryParams}`;
    },
    xing: (url: string) => {
        const baseUrl = socialPlatformsBaseUrl.xing;
        const options = {
            url,
        };
        const queryParams = getQueryParams(options);

        return `${baseUrl}${queryParams}`;
    },
};

export const getSharingUrl = (platformName: string, quote: string = ''): string => {
    if (!socialPlatforms.hasOwnProperty(platformName)) {
        return `${window.location.origin}${window.location.pathname}`;
    }

    const url = `${window.location.origin}${window.location.pathname}`;
    const title = document.title;

    return socialPlatforms[platformName](url, title, quote);
};

export const share = (socialPlatform: string, quote: string = ''): void => {
    const win = window.open(getSharingUrl(socialPlatform, quote), '_blank');

    if (win) {
        // Browser has allowed it to be opened
        win.focus();
    } else {
        // Browser has blocked it
        alert('Bitte erlauben Sie Popups für diese Website, um Links zu teilen');
    }
};
