import { render, staticRenderFns } from "./ArticleHero.vue?vue&type=template&id=6eb80843&scoped=true"
import script from "./ArticleHero.vue?vue&type=script&lang=ts"
export * from "./ArticleHero.vue?vue&type=script&lang=ts"
import style0 from "./ArticleHero.vue?vue&type=style&index=0&id=6eb80843&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb80843",
  null
  
)

export default component.exports