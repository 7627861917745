
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { trackClick } from '@/helpers/webTrekk';

    interface BannerLink {
        href: string;
        text: string;
    }

    @Component
    class Banner extends Vue {
        @Prop({ type: String, required: true }) private readonly title!: string;
        @Prop({ type: String, required: true }) private readonly text!: string;
        @Prop({ type: Object as () => BannerLink, required: true }) private readonly link!: BannerLink;

        private trackClick() {
            trackClick(`Banner link click: ${this.link.text}`);
        }
    }

    export default Banner;
