
    import { Component, Vue } from 'vue-property-decorator';
    import ServerError from '@/components/503.vue';

    @Component({
        components: {
            ServerError,
        },
        metaInfo: {
            title: 'Finanzwissen - 503 / Die Finanzwissen-Seiten sind momentan nicht erreichbar ',
            meta: [
                {
                    name: 'description',
                    content: '503 - Die Finanzwissen-Seiten sind momentan nicht erreichbar ',
                },
            ],
        },
    })
    export default class ServerErorPage extends Vue {}
