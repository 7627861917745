
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import CopyToClipboard from '@/components/CopyToClipboard.vue';

    import { share } from '@/helpers/socialSharing';
    import { trackClick } from '@/helpers/webTrekk';

    @Component({
        components: {
            CopyToClipboard,
        },
    })
    export default class SocialShare extends Vue {
        private share(platform: string): void {
            trackClick(platform);
            share(platform);
        }
    }
