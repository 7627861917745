import router from '@/router';
import { v4 as uuidv4 } from 'uuid';
import getConfigData from '@/helpers/configData';

export const getGtmConfig = async () => {
    const { gtm } = await getConfigData();

    return {
        id: gtm.id,
        loadScript: true,
        nonce: uuidv4(),
        debug: process.env.NODE_ENV === 'development',
        enabled: process.env.NODE_ENV === 'production', // enable on prod
        vueRouter: router,
    };
};
