
    import { Component, Vue } from 'vue-property-decorator';

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';

    import getConfigData from '@/helpers/configData';
    import gtmMixin from '@/mixins/googleTagManager';

    @Component({
        components: {
            Header,
            Footer,
        },
        metaInfo: {
            title: 'DKB',
        },
        mixins: [gtmMixin],
    })
    export default class Home extends Vue {
        private APP_VERSION = `App version: ${this.$store.getters.getAppVersion},\nBuild date: ${document.documentElement.dataset.buildTimestamp}`;

        private mounted() {
            this.fetchExternalConfigurationData();
            this.setNavigationHandler();

            (window as any).addEventListener('keyup', (event: KeyboardEvent) => {
                if (event.ctrlKey && event.key === 'v') {
                    alert(this.APP_VERSION);
                }
            });

            (window as any).pageconfig = {
                contentId: '', // generates content id automatically based on the URL
                executePluginFunction: 'wt_scrollposition',
            };
        }

        private setNavigationHandler() {
            document.addEventListener('click', this.navigationHandler);
            document.addEventListener('keydown', this.navigationHandler);
        }

        private unSetNavigationHandler() {
            document.removeEventListener('click', this.navigationHandler);
            document.removeEventListener('keydown', this.navigationHandler);
        }

        private async fetchExternalConfigurationData() {
            const externalConfigurationData = await getConfigData();
            this.$store.dispatch('setExternalConfigData', externalConfigurationData);
        }

        private navigationHandler(event: Event) {
            // Only when navigating:
            if (event.target == null || (event.target as HTMLElement).nodeName !== 'A') {
                return;
            }

            const isNavigationByKeyboard = event.type === 'keydown';
            this.$store.dispatch('setNavigationHardware', isNavigationByKeyboard);
        }

        private showAppVersion() {
            alert(`Sie verwenden momentan die App-Version ${(window as any).APP_VERSION}`);
        }

        private beforeDestroy(): void {
            if ((window as any).wts != null) {
                (window as any).wts.push(['send', 'page', { contentId: document.title, pageType: 'Exit page' }]);
            }

            this.unSetNavigationHandler();
        }
    }
