
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import TocListItem from '@/types/TocListItem';
    import { trackClick } from '@/helpers/webTrekk';

    @Component
    export default class TableOfContents extends Vue {
        @Prop({ type: Array, default: () => [] }) private readonly list!: TocListItem[];

        private trackTocLinkClick(label: string) {
            trackClick(`ToC click: ${label}`);
        }
    }
