import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm';
import App from './App.vue';
import './assets/scss/main.scss';
import { getGtmConfig } from './helpers/Gtm';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueMeta);

getGtmConfig().then((res) => {
    Vue.use(VueGtm, res);
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
});
