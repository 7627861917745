
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { trackClick } from '@/helpers/webTrekk';
    import EventBus from '@/EventBus';

    @Component
    export default class BackToTop extends Vue {
        public isErrorPage() {
            return (
                ['not-found'].indexOf(this.$route.name as string) !== -1 ||
                ['server-error-page'].indexOf(this.$route.name as string) !== -1
            );
        }

        private scrollToTop(e: Event) {
            e.preventDefault();

            if (e.currentTarget instanceof HTMLElement) {
                e.currentTarget.blur();
            }

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });

            EventBus.$emit('focusBackLink');
        }

        private trackBackClick() {
            trackClick('back to top');
        }
    }
