
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

    import Card from '@/components/Card.vue';
    import GhostCard from '@/components/Card/GhostCard.vue';

    import CardFilters from '@/components/ArticleFilters.vue';
    import NotFound from '@/views/NotFound.vue';
    import ServerErrorPage from '@/views/ServerErrorPage.vue';

    import ArticleData from '@/types/ArticleData';
    import StringStringMap from '@/types/StringStringMap';
    import { IOverview, IOverviewFields } from '@/types/generated/contentful';

    import { extractFields } from '@/helpers/contentHelper';
    import getHomePage from '@/helpers/getHomePage';

    @Component({
        components: {
            Card,
            GhostCard,
            CardFilters,
            NotFound,
            ServerErrorPage,
        },
        metaInfo() {
            const { overviewPage } = this as Home;

            const metaData: { title: string; meta: StringStringMap[] } = {
                title: overviewPage ? `${overviewPage.metaInfoTitle} |  DKB AG` : 'Finanzwissen | DKB AG',
                meta:
                    overviewPage && overviewPage.metaInfoDescription && overviewPage.metaInfoKeywords
                        ? [
                              {
                                  name: 'description',
                                  content: overviewPage.metaInfoDescription,
                              },
                              {
                                  name: 'keywords',
                                  content: overviewPage.metaInfoKeywords,
                              },
                              {
                                  name: 'og:type',
                                  content: 'website',
                              },
                          ]
                        : [],
            };

            if (overviewPage) {
                metaData.meta.push({
                    name: 'title',
                    property: 'og:title',
                    content: overviewPage.metaInfoTitle ? overviewPage.metaInfoTitle : '',
                });

                metaData.meta.push({
                    name: 'image',
                    property: 'og:image',
                    content: overviewPage.metaInfoImage ? overviewPage.metaInfoImage.fields.file.url : '',
                });

                metaData.meta.push({
                    property: 'og:url',
                    content: window.location.href,
                });

                metaData.meta.push({
                    name: 'twitter:card',
                    content: 'summary_large_image',
                });

                metaData.meta.push({
                    name: 'twitter:site',
                    content: '@DKB_de',
                });

                metaData.meta.push({
                    name: 'twitter:title',
                    content: overviewPage.metaInfoTitle ? overviewPage.metaInfoTitle : '',
                });

                return metaData;
            }

            return {};
        },
    })
    export default class Home extends Vue {
        public layout = 'default';
        protected cardSizes = ['big', 'small', 'small', 'small', 'medium', 'medium'];
        private loading = true;
        private cards: ArticleData[] = [];
        private overviewPage: IOverviewFields | null = null;
        private timer: number = 0;
        private serverError = false;

        get allCards(): ArticleData[] {
            return this.$store.getters.getArticles;
        }

        @Watch('$route')
        public onRouteChange() {
            this.setLayout();
            this.loading = true;
            this.displayCards();

            setTimeout(() => {
                this.loading = false;
            });
        }

        protected filterCards(categorySlug: string) {
            this.cards = this.allCards.filter((card: ArticleData) => {
                return card.category.fields.slug === categorySlug;
            });
        }

        protected resetFilters() {
            this.cards = this.allCards;
        }

        private async mounted() {
            this.setLayout();

            try {
                const overviewPageData = await getHomePage;

                this.overviewPage = overviewPageData.items[0].fields;

                if (!this.overviewPage) {
                    throw new Error('Overview data is missing');

                    return false;
                }

                const cards = this.overviewPage.articles
                    .map((i: any) => {
                        return i.fields;
                    })
                    .filter((i) => {
                        // Filter out link entries that are drafts
                        if (i !== undefined) {
                            return i;
                        }
                    });

                this.$store.dispatch('updateArticles', cards);

                this.cards = this.allCards;
                this.displayCards();
            } catch (error) {
                // TODO: display error message
                this.serverError = true;
            }

            this.loading = false;
            this.startWtReadingTimeTimer();
        }

        private destroyed() {
            this.stopWtReadingTimeTimer();
        }

        private onFilterSelect(categorySlug: string) {
            const path = categorySlug ? `/${categorySlug}` : '/';

            if (path !== this.$route.path) {
                this.$router.push({ path });
            }
        }

        private onNotFound() {
            this.$router.replace({ path: '/404' });
        }

        private setLayout() {
            this.layout = this.$route.params.categorySlug ? 'small' : 'default';
        }

        private displayCards() {
            if (this.$route.params.categorySlug) {
                this.filterCards(this.$route.params.categorySlug);
            } else {
                this.resetFilters();
            }

            if ((window as any).wts != null) {
                (window as any).wts.push(['send', 'page', { contentId: 'Finanzwissen - Übersichtsseite' }]);
            }
        }

        private startWtReadingTimeTimer() {
            if ((window as any).wts != null) {
                this.timer = new Date().getTime();
            }
        }

        private stopWtReadingTimeTimer() {
            if ((window as any).wts != null && this.timer !== 0) {
                const now = new Date().getTime();
                (window as any).wts.push([
                    'send',
                    'page',
                    {
                        lengthOfVisit: new Date(now - this.timer - 60 * 60 * 1000).toLocaleTimeString('de'),
                        contentId: 'Finanzwissen - Übersichtsseite',
                    },
                ]);
            }
        }
    }
