
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import InfoGraphicLegend from '@/components/InfoGraphicLegend.vue';

    @Component({
        components: {
            InfoGraphicLegend,
        },
    })
    export default class InfoGraphicHorizontalBar extends Vue {
        @Prop({ type: String, required: true }) private legendTitle!: {};
        @Prop({ type: String, required: true }) private legendSource!: {};
        // Values in the data array should sum up to 100%
        @Prop({ type: Array, required: true }) private data!: {};
    }
