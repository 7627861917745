var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.isArticleNotFound && !_vm.serverError)?_c('main',{staticClass:"article-container"},[(!_vm.isContentReady)?_c('ghost-hero'):_vm._e(),(_vm.articleData && _vm.isContentReady)?_c('article-hero',{attrs:{"heroImgSrc":_vm.getImageUrl(_vm.articleData),"heroImgAlt":_vm.getImageAlt(_vm.articleData.aticleIllustration),"heroCategory":_vm.articleData.category.fields.label,"heroTitle":_vm.articleData.title,"heroTeaser":_vm.articleData.teaser,"heroDateUpdated":_vm.articleData.lastModified,"heroReadingTime":_vm.readingTime,"from":_vm.fromRoutePath}}):_vm._e(),(_vm.articleData)?_c('fw-article',{staticClass:"spacer--90",attrs:{"gridSections":_vm.gridSections,"articleType":_vm.articleData.type}}):_vm._e(),(_vm.featureFlags.socialShareEnabled)?[(_vm.isContentReady)?_c('tooltip'):_vm._e()]:_vm._e(),_c('div',{staticClass:"article-footer grid"},[(_vm.articleSummaryEntries.length)?_c('color-block',{attrs:{"title":"Kurz gesagt","breakout":""}},[_c('ul',{staticClass:"list-inside list-inside--spaced"},_vm._l((_vm.articleSummaryEntries),function(entry,index){return _c('li',{key:`articleSummaryItem_${index}`,domProps:{"innerHTML":_vm._s(entry)}})}),0)]):_vm._e(),(_vm.featureFlags.socialShareEnabled)?[_c('social-share')]:_vm._e(),(_vm.articleData && _vm.articleData.hasOwnProperty('banner'))?[_c('banner',{attrs:{"title":_vm.articleData.banner.fields.title,"text":_vm.articleData.banner.fields.text,"link":{
                        text: _vm.articleData.banner.fields.linkText,
                        href: _vm.articleData.banner.fields.linkUrl,
                    }}})]:_vm._e(),(_vm.articleData && _vm.getRelatedArticles(_vm.articleData).length)?_c('article-footer-links',{attrs:{"related-articles":_vm.getRelatedArticles(_vm.articleData)}}):_vm._e()],2),_c('div',{staticClass:"disclaimers"},[(
                    _vm.articleData &&
                    _vm.articleData.disclaimer_marketingmitteilung &&
                    _vm.disclaimerMarketingmitteilung != null &&
                    Object.entries(_vm.disclaimerMarketingmitteilung)
                )?_c('disclaimer-block',{attrs:{"title":_vm.disclaimerMarketingmitteilung.title,"text":_vm.disclaimerMarketingmitteilung.text}}):_vm._e(),(
                    _vm.articleData &&
                    _vm.articleData.disclaimer_risikohinweis &&
                    _vm.disclaimerRisikohinweis != null &&
                    Object.entries(_vm.disclaimerRisikohinweis)
                )?_c('disclaimer-block',{attrs:{"title":_vm.disclaimerRisikohinweis.title,"text":_vm.disclaimerRisikohinweis.text}}):_vm._e()],1)],2):_vm._e(),(_vm.isArticleNotFound)?_c('not-found'):_vm._e(),(_vm.serverError)?_c('server-error-page'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }