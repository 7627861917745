const categories = [
    {
        label: 'Absichern &amp; Vorsorgen',
        slug: 'absichern-vorsorgen',
    },
    {
        label: 'Freizeit &amp; Mobilität',
        slug: 'freizeit-mobilität',
    },
    {
        label: 'Partnerschaft &amp; Familie',
        slug: 'partnerschaft-familie',
    },
    {
        label: 'Mieten &amp; Eigenheim',
        slug: 'mieten-eigenheim',
    },
    {
        label: 'Aus- & Weiterbildung',
        slug: 'ausbildung-weiterbildung',
    },
];

export default categories;
