
    /* tslint:disable:no-console */
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

    import ArticleData from '@/types/ArticleData';
    import DisclaimerData from '@/types/DisclaimerData';
    import StringStringMap from '@/types/StringStringMap';

    import featureFlags from '@/feature-flags';

    import FwArticle from '@/components/articles/Article.vue';
    import ArticleFooterLinks from '@/components/ArticleFooterLinks.vue';
    import ArticleHero from '@/components/ArticleHero.vue';
    import ColorBlock from '@/components/ColorBlock.vue';
    import DisclaimerBlock from '@/components/DisclaimerBlock.vue';
    import NotFound from '@/views/NotFound.vue';
    import ServerErrorPage from '@/views/ServerErrorPage.vue';
    import SocialShare from '@/components/SocialShare.vue';
    import Tooltip from '@/components/Tooltip.vue';
    import GhostHero from '@/components/GhostHero.vue';
    import Banner from '@/components/Banner.vue';

    import imageHelper from '@/mixins/imageHelper';

    import allArticlesDataPromise from '@/helpers/allArticlesDataPromise';
    import { getDisclaimerMarketingmitteilung, getDisclaimerRisikohinweis } from '@/helpers/getDisclaimers';
    import getReadingTime from '@/helpers/readingTime';
    import { getElementId, getNestedFieldValue, extractFields } from '@/helpers/contentHelper';

    Component.registerHooks(['beforeRouteEnter']);
    @Component({
        components: {
            FwArticle,
            ArticleFooterLinks,
            ArticleHero,
            ColorBlock,
            DisclaimerBlock,
            NotFound,
            ServerErrorPage,
            SocialShare,
            Tooltip,
            GhostHero,
            Banner,
        },
        mixins: [imageHelper],
        metaInfo() {
            const { articleData } = this as ArticlePage;
            const metaData: { title: string; meta: StringStringMap[] } = {
                title: articleData ? `${articleData.title} |  DKB AG` : 'Finanzwissen | DKB AG',
                meta:
                    articleData && articleData.metaInfoDescription && articleData.metaInfoKeywords
                        ? [
                              {
                                  name: 'description',
                                  content: articleData.metaInfoDescription,
                              },
                              {
                                  name: 'keywords',
                                  content: articleData.metaInfoKeywords,
                              },
                          ]
                        : [],
            };

            if (articleData) {
                metaData.meta.push({
                    property: 'og:title',
                    content: articleData.title,
                });

                metaData.meta.push({
                    property: 'og:description',
                    content: articleData.metaInfoDescription as string,
                });

                metaData.meta.push({
                    property: 'og:type',
                    content: 'article',
                });

                metaData.meta.push({
                    property: 'article:modified_time',
                    content: articleData.lastModified,
                });

                metaData.meta.push({
                    property: 'og:image',
                    content: `https:${(this as ArticlePage).getArticleImageUrl(articleData)}`,
                });

                metaData.meta.push({
                    property: 'og:url',
                    content: `https://finanzwissen.dkb.de${window.location.pathname}`,
                });

                metaData.meta.push({
                    name: 'twitter:card',
                    content: 'summary_large_image',
                });

                metaData.meta.push({
                    name: 'twitter:site',
                    content: '@DKB_de',
                });

                metaData.meta.push({
                    name: 'twitter:title',
                    content: articleData.title,
                });

                metaData.meta.push({
                    name: 'twitter:image',
                    content: `https:${(this as ArticlePage).getArticleImageUrl(articleData)}`,
                });

                return metaData;
            }

            return {};
        },
    })
    export default class ArticlePage extends Vue {
        private articleComponent: any = null;
        private articleData: ArticleData | null = null;
        private readingTime = '';
        private isContentReady = false;
        private fromRoutePath = '';
        private isArticleNotFound = false;
        private timer: number = 0;
        private serverError = false;
        private featureFlags = featureFlags;

        get allArticles(): ArticleData[] {
            return this.$store.getters.getArticles;
        }

        get disclaimerMarketingmitteilung() {
            return this.$store.getters.getDisclaimerMarketingmitteilung;
        }

        get disclaimerRisikohinweis() {
            return this.$store.getters.getDisclaimerRisikohinweis;
        }

        // Create a separate array when a "stickyToc" comes up somewhere in the article contents array,
        // e.g. gridSections = [ ['articleContentElements without stickyToc'],
        // ['articleContentElements with stickyToc']]:
        get gridSections(): any[][] {
            const gridSections: any[][] = [];
            let gridSection: any[] = [];

            if (this.articleData == null || this.articleData.article == null) {
                return gridSections;
            }

            this.articleData.article.content.forEach((contentElement: any) => {
                if (getElementId(contentElement) === 'stickyToc' && gridSection.length) {
                    gridSections.push([...gridSection]);
                    gridSection = [];
                }

                gridSection.push(contentElement);

                if (Number(this.$store.getters.getExternalConfigData.logArticleComponents) === 1) {
                    // console.dir(this.articleData);
                    console.log(`${gridSection.length - 1}. ${getElementId(gridSection[gridSection.length - 1])}`);
                }
            });

            if (gridSection.length) {
                gridSections.push([...gridSection]);

                if (Number(this.$store.getters.getExternalConfigData.logArticleComponents) === 1) {
                    console.dir(gridSection);
                }
            }

            return gridSections;
        }

        public fetchArticle() {
            this.stopWtReadingTimeTimer();

            const allArticlesDataBySlug = this.allArticles.filter((item) => {
                return item.slug === this.$route.params.slug;
            });

            if (!allArticlesDataBySlug.length) {
                // navigate away when no article was found
                this.isArticleNotFound = true;
                this.$router.push({ name: 'not-found' });
            }

            this.articleData = allArticlesDataBySlug[0];
            this.$nextTick(() => {
                this.getReadingTimeLabel();
                this.isContentReady = true;

                if (this.articleData != null && (window as any).wts != null) {
                    (window as any).wts.push([
                        'send',
                        'page',
                        { contentId: `Finanzwissen - ${this.articleData.title}` },
                    ]);
                }
            });

            this.startWtReadingTimeTimer();
        }

        public getRelatedArticles(currentArticle: ArticleData) {
            const relatedArticles: ArticleData[] =
                currentArticle.hasOwnProperty('relatedArticles') && currentArticle.relatedArticles
                    ? currentArticle.relatedArticles.map((article: { fields: ArticleData }) => {
                          return article.fields;
                      })
                    : [];
            const requiredArticlesCount = 2;
            let missingArticles = requiredArticlesCount - relatedArticles.length;

            if (!missingArticles) {
                return relatedArticles;
            }

            const category = currentArticle.category.fields.slug;
            const slug = currentArticle.slug;
            // Collect related articles slugs
            const relatedArticlesSlugs = relatedArticles.map((article) => {
                return article.slug;
            });
            // Collect suitable suggestions
            const filteredAllArticles = this.allArticles.filter((article) => {
                return article.slug !== slug && relatedArticlesSlugs.indexOf(article.slug) === -1;
            });
            const articlesByCategory = filteredAllArticles.filter((article) => {
                return article.category.fields.slug === category;
            });
            // Add suggestions
            const suggestions = [];

            while (missingArticles) {
                let randomArticle = null;

                if (articlesByCategory.length) {
                    randomArticle = articlesByCategory.pop();
                } else {
                    randomArticle = filteredAllArticles[Math.floor(Math.random() * filteredAllArticles.length)];

                    while (suggestions.indexOf(randomArticle) !== -1) {
                        randomArticle = filteredAllArticles[Math.floor(Math.random() * filteredAllArticles.length)];
                    }
                }

                suggestions.push(randomArticle);
                missingArticles--;
            }

            return [...relatedArticles, ...suggestions];
        }

        public getReadingTimeLabel() {
            const articleElement: HTMLElement = document.querySelector('.template-container') as HTMLElement;

            if (articleElement == null) {
                return '';
            }

            this.readingTime = getReadingTime(articleElement.innerText);
        }

        get articleSummaryEntries() {
            let entries = [];

            if (
                this.articleData != null &&
                this.articleData.articleSummary != null &&
                this.articleData.articleSummary.content.length &&
                this.articleData.articleSummary.content[0].content &&
                this.articleData.articleSummary.content[0].content.length
            ) {
                entries = this.articleData.articleSummary.content[0].content
                    .filter((entry: any) => {
                        if (entry.hasOwnProperty('content')) {
                            return entry;
                        }
                    })
                    .map((entry: any) => entry.content[0].content[0].value);
            }

            return entries;
        }

        @Watch('$route')
        public onRouteChange() {
            this.fetchArticle();
        }

        private async updateAllArticles() {
            try {
                const data = await allArticlesDataPromise;

                this.$store.dispatch('updateArticles', extractFields(data));
                this.fetchArticle();
            } catch (error) {
                if (!this.isArticleNotFound) {
                    this.serverError = true;
                }
            }
        }

        private async getDisclaimerMarketingmitteilung() {
            try {
                const disclaimerMarketingmitteilung = await getDisclaimerMarketingmitteilung;

                this.$store.dispatch('setDisclaimerMarketingmitteilung', {
                    title: disclaimerMarketingmitteilung.fields.titel,
                    text: this.getParagraphsFromTextContent(disclaimerMarketingmitteilung.fields.text.content),
                });
            } catch (error) {
                this.serverError = true;
            }
        }

        private async getDisclaimerRisikohinweis() {
            try {
                const disclaimerRisikohinweis = await getDisclaimerRisikohinweis;

                this.$store.dispatch('setDisclaimerRisikohinweis', {
                    title: disclaimerRisikohinweis.fields.titel,
                    text: this.getParagraphsFromTextContent(disclaimerRisikohinweis.fields.text.content),
                });
            } catch (error) {
                this.serverError = true;
            }
        }

        private getParagraphsFromTextContent(textContent: any[]) {
            return textContent.map((paragraphObject) => `<p>${paragraphObject.content[0].value}</p>`).join('');
        }

        private beforeRouteEnter(to: any, from: any, next: any) {
            next((ArticlePageComponent: any) => {
                ArticlePageComponent.fromRoutePath = from.fullPath;
            });
        }

        private mounted() {
            if (!this.$store.getters.getDisclaimerMarketingmitteilung) {
                this.getDisclaimerMarketingmitteilung();
            }

            if (!this.$store.getters.getDisclaimerRisikohinweis) {
                this.getDisclaimerRisikohinweis();
            }

            if (!this.allArticles || !this.allArticles.length) {
                this.updateAllArticles();

                return false;
            }

            this.fetchArticle();
        }

        private destroyed() {
            this.stopWtReadingTimeTimer();
        }

        private getArticleImageUrl(articleData: any) {
            if (articleData && articleData.aticleIllustration && articleData.aticleIllustration[0]) {
                return (articleData.aticleIllustration[0] as { fields: { file: { url: string } } }).fields.file.url;
            }

            return '';
        }

        private startWtReadingTimeTimer() {
            if (this.articleData != null && (window as any).wts != null) {
                this.timer = new Date().getTime();
            }
        }

        private stopWtReadingTimeTimer() {
            if (this.articleData != null && (window as any).wts != null && this.timer !== 0) {
                const now = new Date().getTime();
                (window as any).wts.push([
                    'send',
                    'page',
                    {
                        customParameter: {
                            1: `lengthOfVisit: ${new Date(now - this.timer - 60 * 60 * 1000).toLocaleTimeString('de')}`,
                            2: `contentId: Finanzwissen - ${this.articleData.title}`,
                        },
                    },
                ]);
            }
        }
    }
    /* tslint:enable:no-console */
