
    import { Component, Vue } from 'vue-property-decorator';
    import EventBus from '@/EventBus';

    @Component
    export default class ScrollProgressBar extends Vue {
        private page: HTMLElement = document.documentElement ? document.documentElement : document.body;
        private lastScrollY = 0;
        private ticking = false;
        private lastScrollHeight = 0;
        private lastWindowHeight = 0;
        private maxScrollPercentage = 0;

        protected updateProgressBarWidth(): void {
            if (this.$refs.progressbar == null) {
                return;
            }

            const currentScrollY = this.lastScrollY;
            const invisibleHeight = this.lastScrollHeight - this.lastWindowHeight;
            const scrollPercentage = (currentScrollY / invisibleHeight) * 100 - 100;
            this.maxScrollPercentage = Math.max(this.maxScrollPercentage, scrollPercentage);

            (this.$refs.progressbar as HTMLElement).style.left = `${scrollPercentage}%`;
            this.ticking = false;
        }

        protected requestTick(): void {
            if (!this.ticking) {
                // Needed because the whole document tree might not be ready when this component is ready:
                if (this.lastScrollHeight !== this.page.scrollHeight) {
                    this.updateHeights();
                }

                requestAnimationFrame(this.updateProgressBarWidth);
                this.ticking = true;
            }
        }

        protected updateHeights() {
            this.lastWindowHeight = window.innerHeight;
            this.lastScrollHeight = this.page.scrollHeight;
        }

        protected onResize(): void {
            this.updateHeights();
            this.requestTick();
        }

        protected onScroll(): void {
            this.lastScrollY = window.pageYOffset;
            this.requestTick();
        }

        protected beforeMount(): void {
            EventBus.$on('articleWillChange', this.trackScrollDepth);
        }

        protected mounted(): void {
            window.addEventListener('scroll', this.onScroll, { passive: true });
            window.addEventListener('resize', this.onResize, false);
            this.updateHeights();
        }

        protected trackScrollDepth(): void {
            if ((window as any).wts != null) {
                (window as any).wts.push(['send', 'scroll', { maxScrollPercentage: this.maxScrollPercentage }]);
            }
        }

        protected beforeDestroy(): void {
            window.removeEventListener('scroll', this.onScroll, false);
            window.removeEventListener('resize', this.onResize, false);
            EventBus.$off('articleWillChange');
            this.trackScrollDepth();
        }
    }
