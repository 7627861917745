
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class Author extends Vue {
        @Prop({ type: String, required: true }) private readonly imgSrc!: string;
        @Prop({
            type: Array,
            default() {
                return [];
            },
        })
        @Prop({ type: String, default: '' })
        private readonly imgAlt!: string;
        @Prop({ type: String, required: true }) private text!: string;
    }
