export const trackClick = (identifier: string) => {
    if ((window as any).wts != null) {
        (window as any).wts.push(['send', 'click', { linkId: identifier }]);
    }
};

export const trackPage = (contentId: string, data: any) => {
    if ((window as any).wts != null) {
        (window as any).wts.push(['send', 'page', { contentId, customEcommerceParameter: data }]);
    }
};
