import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export const getElementId = (contentElement: any) =>
    ['embedded-entry-block', 'embedded-entry-inline'].includes(contentElement.nodeType)
        ? contentElement.data.target.sys.contentType.sys.id
        : contentElement.nodeType;

export const getNestedFieldValue = (element: any, fieldName: string): string =>
    element.data.target.fields[fieldName].content[0].content[0].value;

// Use to extract only the fields from Contentful response with an array of data:
export const extractFields = (data: { items: Array<{ fields: any }> }) => data.items.map((item) => item.fields);

const widthClasses: any = {
    Mobil: 'only-small',
    Tablet: 'only-medium',
    TabletUndGrößer: 'only-medium-up',
    default: 'only-large',
};

export const getWidthClass = (width: string) =>
    !widthClasses.hasOwnProperty(width) ? widthClasses.default : widthClasses[width];

const colorBlockThemes: any = {
    Gelb: 'yellow',
    default: 'blue',
};

export const getColorBlockThemeFor = (color: string) =>
    !colorBlockThemes.hasOwnProperty(color) ? colorBlockThemes.default : colorBlockThemes[color];

const tableLayouts: any = {
    vertikal: 'vertical',
    default: 'horizontal',
};

export const getTableLayoutFor = (direction: string) =>
    !tableLayouts.hasOwnProperty(direction) ? tableLayouts.default : tableLayouts[direction];

export const getColorBlockParagraphs = (element: any) =>
    element.data.target.fields.text.content[0].nodeType === 'paragraph'
        ? element.data.target.fields.text.content.map((paragraphObject: any) => documentToHtmlString(paragraphObject))
        : [];

export const getColorBlockListItems = (element: any) =>
    element.data.target.fields.text.content[0].nodeType === 'unordered-list'
        ? element.data.target.fields.text.content[0].content.map((listItemObject: any) =>
              documentToHtmlString(listItemObject),
          )
        : [];

const quoteFloatClasses: any = {
    Links: 'left',
    Rechts: 'right',
    default: null,
};

export const getQuoteFloatClass = (float: string): string | null =>
    !quoteFloatClasses.hasOwnProperty(float) ? quoteFloatClasses.default : quoteFloatClasses[float];
