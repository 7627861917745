
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ArticleData from '@/types/ArticleData';

    import Card from '@/components/Card.vue';

    import EventBus from '@/EventBus';

    import { trackClick } from '@/helpers/webTrekk';

    @Component({
        components: {
            Card,
        },
    })
    export default class ArticleFooterLinks extends Vue {
        @Prop({ type: Array, default: () => [], required: false }) private relatedArticles!: ArticleData[];
        @Prop({ type: String, default: 'Diese Artikel könnten dich auch interessieren' }) private title!: string;

        private trackRecommendationClick(slug: string, relatedArticleIndex: number) {
            trackClick(`Article recommendation click: ${relatedArticleIndex % 2 === 0 ? 'L' : 'R'}_${slug}`);
        }

        private getTeaser(relatedArticle: ArticleData) {
            if (relatedArticle.shortTeaser) {
                return relatedArticle.shortTeaser;
            }

            if (relatedArticle.teaser) {
                return relatedArticle.teaser;
            }

            return '';
        }

        private getCategory(relatedArticle: ArticleData) {
            if (!relatedArticle.category || !relatedArticle.category.fields) {
                return '';
            }

            return relatedArticle.category.fields.label;
        }

        private emitArticleChange() {
            EventBus.$emit('articleWillChange');
        }
    }
