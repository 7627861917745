import Vue from 'vue';


const gtmMixin = Vue.extend({
    async mounted() {
        this.trackPage();
    },
    methods: {
        trackPage() {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'page_view',
                    environment_data: {
                        site_environment: process.env.NODE_ENV,
                    },
                    page_data: {
                        page_url: window.location.href,
                        page_path: window.location.pathname,
                        page_title: document.title,
                        login_status: '0',
                    },
                    tc_archiv: {
                            // @ts-ignore
                        tc_vars: window?.tc_vars,
                    },
                });
            }
        },
    },
});

export default gtmMixin;

