
    import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

    import clearSelection from '@/helpers/clearSelection.ts';
    import getElementCoordinates from '@/helpers/getElementCoordinates.ts';
    import getParentByClassname from '@/helpers/getParentByClassname.ts';
    import getSelection from '@/helpers/getSelection.ts';
    import { share } from '@/helpers/socialSharing.ts';
    import { trackClick } from '@/helpers/webTrekk';

    @Component
    export default class Tooltip extends Vue {
        @Prop({ type: Object }) private position!: { x: number; y: number };

        private copying = false;
        private isTooltipDisabled = true;
        private shouldDisplayTooltip = false;
        private selectedText = '';
        private selectionPosition = {
            x: 0,
            y: 0,
        };

        private isSupported() {
            return document.queryCommandSupported('copy');
        }

        private copy() {
            this.copying = true;
            document.execCommand('copy');

            setTimeout(() => {
                this.copying = false;
            }, 5000);
        }

        private removeOnSelectionEventListener() {
            const elements = document.querySelectorAll('.grid__line:not(.infographic)');

            Array.prototype.forEach.call(elements, (el) => {
                el.removeEventListener('mouseup', this.onSelection);
            });
        }

        private setupOnSelectionEventListener() {
            const elements = [
                ...document.querySelectorAll('.article .grid__line:not(.infographic)'),
                ...document.querySelectorAll('.article .color-block:not(.color-block--blue)'),
            ];

            elements.forEach((el) => {
                el.addEventListener('mouseup', this.onSelection);
            });

            document.addEventListener('mouseup', (e) => {
                if (
                    (e.target as HTMLElement).classList.contains('tooltip') ||
                    (getParentByClassname(e.target as Node, 'tooltip') &&
                        (getParentByClassname(e.target as Node, 'tooltip') as HTMLElement).classList.contains(
                            'tooltip',
                        ))
                ) {
                    return false;
                }

                this.shouldDisplayTooltip = false;
            });
        }

        private isSelectionWithinSameElement(selection: Selection) {
            const anchorNodeParent = selection.anchorNode
                ? getParentByClassname(selection.anchorNode as Node, 'grid__line')
                : null;
            const focusNodeParent = selection.focusNode
                ? getParentByClassname(selection.focusNode as Node, 'grid__line')
                : null;

            if (anchorNodeParent && focusNodeParent && anchorNodeParent !== focusNodeParent) {
                return false;
            }

            return true;
        }

        private getTooltipPosition(text: string, selection: Selection, transitionOffset: number) {
            this.selectedText = text;
            const selectionRangeObject = selection.getRangeAt(0);
            const selectionRangeAreaRectangle = selectionRangeObject.getBoundingClientRect();
            const coords = getElementCoordinates(selectionRangeObject);
            const tooltip = this.$refs.tooltip as HTMLElement;
            const tooltipArrow = tooltip.querySelector('.tooltip__arrow') as HTMLElement;
            // Reset arrow position
            tooltipArrow.style.left = '20px';
            tooltipArrow.style.right = 'auto';
            const tooltipArrowLeft = (getComputedStyle(tooltipArrow).left as string).replace('px', '');
            const tooltipWidth = tooltip.getBoundingClientRect().width;
            const tooltipHeight = tooltip.getBoundingClientRect().height + 20;

            let x =
                coords.left +
                (selectionRangeAreaRectangle.width / 2 -
                    (tooltipArrow.getBoundingClientRect().width / 2 + parseInt(tooltipArrowLeft, 10)));
            // Bring it a little above the actual position for transition to work
            const y = coords.top - tooltipHeight - transitionOffset;

            // Position tooltip right aligned
            if (x + tooltipWidth > window.innerWidth) {
                // Set arrow position
                tooltipArrow.style.left = 'auto';
                tooltipArrow.style.right = '8px';

                x = coords.left + selectionRangeAreaRectangle.width - tooltipWidth;
            }

            return {
                x,
                y,
            };
        }

        private onSelection(e: Event) {
            this.shouldDisplayTooltip = false;
            this.copying = false;

            setTimeout(() => {
                const { text, selection } = getSelection();

                if (!selection || !text) {
                    return false;
                }

                if (!this.isSelectionWithinSameElement(selection)) {
                    return false;
                }

                if (text.length) {
                    // Show tooltip
                    const transitionOffset = 25;
                    const tooltipPosition = this.getTooltipPosition(text, selection, transitionOffset);

                    // Prepare for transition
                    this.selectionPosition = {
                        x: tooltipPosition.x,
                        y: tooltipPosition.y,
                    };

                    // Set to actual position
                    setTimeout(() => {
                        this.selectionPosition = {
                            x: tooltipPosition.x,
                            y: tooltipPosition.y + transitionOffset,
                        };

                        this.shouldDisplayTooltip = true;
                    }, 100);
                }
            });
        }

        private onResize(e: Event) {
            // Hide tooltip and remove selection
            clearSelection();
            this.shouldDisplayTooltip = false;
            this.selectionPosition = {
                x: 0,
                y: 0,
            };

            if (window.innerWidth < 741) {
                this.removeOnSelectionEventListener();
                this.isTooltipDisabled = true;

                return false;
            }

            if (this.isTooltipDisabled) {
                this.setupOnSelectionEventListener();
                this.isTooltipDisabled = false;
            }
        }

        private share(platform: string) {
            trackClick(platform);
            const { text, selection } = getSelection();

            if (!selection || !text) {
                return false;
            }

            share(platform, text);
        }

        private mounted() {
            this.$nextTick(() => {
                this.setupOnSelectionEventListener();
                this.isTooltipDisabled = false;

                window.addEventListener('resize', this.onResize);
            });
        }

        private destroyed() {
            window.removeEventListener('resize', this.onResize);
        }
    }
