/* tslint:disable:no-var-requires */
const contentful = require('contentful');
/* tslint:enable:no-var-requires */

import getConfigData from '@/helpers/configData';

let contentfulClient: any = null;

const getClient = async () => {
    if (contentfulClient == null) {
        const { ctf } = await getConfigData();

        let clientConfig = {
            space: ctf.spaceId,
            environment: ctf.environment,
            accessToken: ctf.deliveryToken,
            removeUnresolved: true,
        };

        if (ctf.enablePreview) {
            clientConfig = Object.assign(clientConfig, {
                host: ctf.previewHost,
                accessToken: ctf.deliveryPreviewToken,
            });
        }

        contentfulClient = contentful.createClient(clientConfig);
    }

    return contentfulClient;
};

export default getClient;
