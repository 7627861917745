var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.serverError)?_c('main',{staticClass:"home"},[_c('div',{staticClass:"home__header grid"},[(_vm.overviewPage && _vm.overviewPage.logoImage && _vm.overviewPage.logoURL)?_c('div',{staticClass:"home__campaign-logo spacer--60-desktop"},[_c('a',{attrs:{"href":_vm.overviewPage.logoURL,"target":"_blank","tabindex":"0"}},[_c('img',{attrs:{"alt":'logoImage',"src":`${_vm.overviewPage.logoImage.fields.file.url}`,"height":"64px","width":"134px"}})])]):_vm._e(),(!_vm.overviewPage)?_c('h1',{staticClass:"home__title grid__line home__no-logo"},[_vm._v(" Finanzwissen ")]):(_vm.overviewPage)?_c('h1',{staticClass:"home__title grid__line",class:{ 'home__no-logo': !_vm.overviewPage.logoImage }},[_vm._v(" Finanzwissen ")]):_vm._e(),(_vm.overviewPage)?_c('p',{staticClass:"home__teaser grid__line"},[_vm._v(_vm._s(_vm.overviewPage.teaser))]):_vm._e(),(!_vm.overviewPage)?_c('p',{staticClass:"home__teaser grid__line"},[_c('span',{staticClass:"ghost-line"}),_c('span',{staticClass:"ghost-line"}),_c('span',{staticClass:"ghost-line"})]):_vm._e()]),_c('section',{staticClass:"spacer--60"},[_c('card-filters',{on:{"filterSelect":_vm.onFilterSelect,"notFound":_vm.onNotFound}})],1),_c('div',{staticClass:"visually-hidden",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.cards.length)+" Artikel "+_vm._s(_vm.cards.length === 1 ? 'wurde' : 'wurden')+" gefunden ")]),(!_vm.overviewPage)?_c('ul',{class:[`home__cards-list home__cards-list--ghost home__cards-list--${_vm.layout} grid`],attrs:{"id":"article-list","role":"list"}},_vm._l((new Array(6)),function(card,index){return _c('li',{key:index,class:[
                    'spacer--60 home__card',
                    _vm.layout === 'default' ? `home__card--${_vm.cardSizes[index % 6]}` : '',
                ],attrs:{"role":"listitem"}},[_c('ghost-card',{attrs:{"cardSize":_vm.layout === 'default' ? `${_vm.cardSizes[index % 6]}` : 'small'}})],1)}),0):_vm._e(),(_vm.overviewPage)?_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.cards && _vm.cards.length)?_c('ul',{class:[`home__cards-list home__cards-list--${_vm.layout} grid`],attrs:{"role":"list"}},_vm._l((_vm.cards),function(card,cardIndex){return _c('li',{key:`${card.slug}-${card.imgSrc}`,class:[
                        'spacer--60 home__card',
                        _vm.layout === 'default' ? `home__card--${_vm.cardSizes[cardIndex % 6]}` : '',
                    ],attrs:{"role":"listitem"}},[_c('card',{attrs:{"aticleIllustration":card.aticleIllustration,"cardSize":_vm.layout === 'default' ? `${_vm.cardSizes[cardIndex % 6]}` : 'small',"cardIndex":cardIndex,"category":card.category.fields.label,"slug":card.slug,"teaser":card.shortTeaser ? card.shortTeaser : card.teaser,"title":card.title}})],1)}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"spacer--above-footer"})],1):_vm._e(),(_vm.serverError)?_c('server-error-page'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }