import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import Article from '@/views/Article.vue';
import NotFound from '@/views/NotFound.vue';
import ServerErrorPage from '@/views/ServerErrorPage.vue';
import categories from '@/data/categories';

const categorySlugs = categories.map((category) => category.slug);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/finanzwissen',
            redirect: '/',
        },
        {
            path: '/finanzwissen/:slug',
            redirect: '/:slug',
        },
        {
            path: '/404',
            alias: '*',
            name: 'not-found',
            component: NotFound,
        },
        {
            path: '/503',
            name: 'server-error-page',
            component: ServerErrorPage,
        },
        {
            path: `/:categorySlug(${categorySlugs.join('|')})?`,
            name: 'home',
            component: Home,
        },
        {
            path: '/:slug',
            name: 'article',
            component: Article,
        },
        {
            path: '/spartypen',
            name: 'spartypen',
            component: () => import(/* webpackChunkName: "about" */ './views/Spartypen.vue'),
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            if (to.hash) {
                return {
                    selector: to.hash,
                };
            }

            return { x: 0, y: 0 };
        }
    },
});

export default router;
