const getSelection = () => {
    let text = '';
    let selection = null;

    if (window.getSelection) {
        selection = window.getSelection();
        text = selection ? selection.toString() : '';
    }

    return {
        text,
        selection,
    };
};

export default getSelection;
