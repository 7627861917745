var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"infographic grid--breakout"},[_c('div',{staticClass:"infographic__circles"},_vm._l((_vm.entries),function(entry,index){return _c('div',{key:`infographic-entry-${index}`,staticClass:"infographic__entry"},[_c('div',{staticClass:"infographic__percentage-circle only-small",style:({
                    borderWidth: Math.max(3, (10 * entry.width) / 100) + 'px',
                    height: entry.width + 'px',
                    width: entry.width + 'px',
                })}),_c('div',{staticClass:"infographic__percentage-circle only-medium-up",style:({
                    borderWidth: Math.max(3, (10 * entry.width) / 100) + 'px',
                    height: entry.width + 'px',
                    marginTop: 100 - entry.width + 'px',
                    width: entry.width + 'px',
                })}),_c('div',{staticClass:"infographic__value-and-label"},[_c('div',{staticClass:"infographic__percentage-value"},[_vm._v(_vm._s(entry.value)+"%")]),_c('div',{staticClass:"infographic__label"},[_vm._v(_vm._s(entry.label))])])])}),0),_c('info-graphic-legend',{attrs:{"position":"top-right","title":_vm.title,"source":_vm.source}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }